// Find all buttons with the `alert` class on the page.
const toggleList = document.querySelectorAll('input.toggle');


const prosumerA = document.querySelector('#prosumer-a');
const prosumerB = document.querySelector('#prosumer-b');
const prosumerC = document.querySelector('#prosumer-c');
const toggleCommunity = document.querySelector('#toggleCommunity');
const toggleEvu = document.querySelector('#toggleEvu');
const toggleSum = document.querySelector('#toggleSum');
const verbrauch = 273.20;

// Handle clicks on each button.
toggleList.forEach((checkbox) => {
    checkbox.addEventListener('click', () => {
        const dataNetz = checkbox.dataset.netz;
        const dataHouse = checkbox.dataset.house;
        const toggleNetz = document.getElementById(dataNetz);
        toggleNetz.style.display = checkbox.checked ? "block" : "none";

        const toggleLight = document.getElementById(dataHouse)
        toggleLight.classList.toggle('light');
        toggleNetz.classList.toggle('online');

        let prosumerList = [];
        let betragList = [];

        function push(leistung, kosten) {
            prosumerList.push(leistung);
            betragList.push(leistung * kosten);
        }

        push(0, 0);

        if (prosumerA.checked) {
            push(55.6, 0.2382);
        }
        if (prosumerB.checked) {
            push(33.8, 0.2501);
        }
        if (prosumerC.checked) {
            push(20, 0.2858);
        }
        if (prosumerList.length > 0) {
            const sumCommunity = prosumerList.reduce((partialSum, a) => partialSum + a).toFixed(2);
            const sumEvu = (verbrauch - sumCommunity).toFixed(2);
            const betragCommunity = betragList.reduce((partialSum, a) => partialSum + a);
            const betragEvu = sumEvu * 0.32;
            const betragGesamt = (betragCommunity + betragEvu).toFixed(2);

            toggleCommunity.innerHTML = sumCommunity.toString().replace(".", ",");
            toggleEvu.innerHTML = sumEvu.toString().replace(".", ",");
            toggleSum.innerHTML = betragGesamt.toString().replace(".", ",");
        }
    });
});
